export const lawyers = {
  spanish: {
    title: "ÁREAS DE PRÁCTICA LEGAL",

    procesosCiviles: "Procesos Civiles",
    derechoMigratorio: "Derecho Migratorio",
    propiedadHorizontal: "Propiedad Horizontal",
    tierrasPropiedades: "Titulación y Regularización de Tierras",
    derechoFiscal: "Derecho Fiscal",
    derechoFamilia: "Derecho de Familia",
    propiedadIntelectual: "Propiedad Intelectual",
    derechoCoorporativo: "Derecho Corporativo",
    otrosServicios: "Otros Servicios",

    TPC: "PROCESOS CIVILES",
    line11:
      "Es muy amplia la rama del Derecho Civil, pero es justo decir que la misma pretende garantizar el legítimo derecho del individuo en situaciones varias, siendo una de las más comunes las relaciones contractuales. Los abogados de Consulting Panamá le asistirán en la resolución de conflictos y en los litigios en la materia civil. Así mismo contáctenos para:",
    line12: "Elaboración de contratos",
    line13: "Acciones derivadas de incumplimiento de contrato",
    line14: "Reclamos por daños y perjuicios",
    line15: "Elaboración de testamentos",
    line16: "Procesos de sucesión testada e intestada",
    line17: "Prescripción adquisitiva de dominio",
    line18:
      "Consultas, asesoría y representación judicial en materia civil, incluyendo protección al consumidor",
    line19: " hoy o contáctenos a ",

    TTTRP: "TITULACIÓN DE TIERRAS Y REGULARIZACIÓN DE PROPIEDADES",
    line20:
      'El artículo 423 de nuestro Código Civil establece que "La posesión se adquiere por la ocupación material de la cosa o derecho poseído, o por el hecho de quedar estos sujetos a la acción de nuestra voluntad, o por los actos propios y formalidades legales establecidos para adquirir tal derecho". Una vez establecidos, los derechos posesorios sobre tierras nacionales son susceptibles de titulación. En Consulting Panama contamos con amplio conocimiento y vasta experiencia en los procesos de titulación de derechos posesorios.',
    line21:
      "En materia de regularización, le brindamos la asesoría y asistencia legal que requiere para mantener sus impuestos de inmueble al día, y para solicitar las exoneraciones que pudieran corresponderle. También nos ocupamos de sus segregaciones, traspasos de propiedad, y todo tipo de actualización registral y catastral.",
    line45: " hoy o contáctenos a ",

    TDF: "DERECHO DE FAMILIA",
    line22: "Consulte hoy referente a sus procesos de:",
    line23: "Pensión alimenticia",
    line24: "Guarda y crianza",
    line25: "Reglamentación de visita",
    line26: "Impugnación de paternidad",
    line27: "Interdicción (representación del incapaz)",
    line28: "Divorcio",
    line29:
      "Separación de bienes - Liquidación del régimen económico matrimonial",

    TPI: "PROPIEDAD INTELECTUAL",
    line30: "Realizamos los siguientes trámites:",
    line31:
      "Registro de obras ante la Dirección de Derecho de Autor del Ministerio de Cultura",
    line32:
      "Solicitudes de registro de la propiedad industrial ante la Dirección General del Registro de la Propiedad Industrial del Ministerio de Comercio e Industrias (DIGERPI)",
    line33: "Requisitos de la solicitud de registro de marca:",
    line34:
      "Nombre, nacionalidad, domicilio preciso y número de cédula o documento de identidad personal, del solicitante y del abogado;",
    line35:
      "Si se trata de una persona jurídica, su razón social, lugar de constitución y domicilio preciso;",
    line36:
      "Denominación y/o diseño de la marca, tal como será usada en el mercado;",
    line37:
      "Especificación de los productos o servicios en los cuales la marca es o será usada.",

    TOS: "OTROS SERVICIOS",
    line38: "Autenticación y apostilla de documentos",
    line39: "Certificados de registro público",
    line40: "Obtención de paz y salvos",
    line41: "Avisos de operación",
    line42: "Protocolización de escritura en notaria",
    line43: "Autenticación de documentos en notaria",
    line46: "Apertura de cuentas bancarias",
    line44: "Contáctenos",
    line45: " en referencia a otros servicios no listados.",
    call: "Llame",
  },

  english: {
    title: "AREAS OF LEGAL PRACTICE",

    procesosCiviles: "Civil Process",
    derechoMigratorio: "Inmigration Law",
    propiedadHorizontal: "Horizontal Property",
    tierrasPropiedades: "Land Tenure and Regularization",
    derechoFiscal: "Tax Law",
    derechoFamilia: "Family Right",
    propiedadIntelectual: "Intellectual Property",
    derechoCoorporativo: "Corporate Law",
    otrosServicios: "Other Services",

    TPC: "Civil Process",
    line11:
      "The branch of Civil Law is very broad, but it is fair to say that it aims to guarantee the legitimate right of the individual in various situations, one of the most common being contractual relationships. The lawyers at Consulting Panama will assist you in resolving conflicts and in litigation in civil matters. Likewise, contact us for:",
    line12: "Preparation of contracts",
    line13: "Actions arising from breach of contract",
    line14: "Claims for damages",
    line15: "Preparation of wills",
    line16: "Testate and intestate probate",
    line17: "Domain acquisitive prescription",
    line18:
      "Consultations, advice and judicial representation in civil matters, including consumer protection",
    line19: " today or contact us at ",

    TTTRP: "LAND TENURE AND REGULARIZATION",
    line20:
      'Article 423 of our Civil Code establishes that "Possession is acquired by the material occupation of the thing or right possessed, or by the fact that these are subject to the action of our will, or by the proper acts and legal formalities established to acquire such right." Once established, possessory rights over national lands are susceptible to titling. At Consulting Panama we have extensive knowledge and vast experience in the titling processes of possessory rights.',
    line21:
      "In matters of regularization, we provide you with the advice and legal assistance you require to keep your property taxes up to date, and to request any exemptions that may apply. We also take care of your segregations, property transfers, and all types of registry updates and cadastral.",
    line45: " today or contact us at ",

    TDF: "FAMILY RIGHT",
    line22: "Inquire today regarding the following processes:",
    line23: "Alimony",
    line24: "Guarding and breeding",
    line25: "Visiting regulations",
    line26: "Paternity challenge",
    line27: "Interdiction (representation of the incapable)",
    line28: "Divorce",
    line29:
      "Separation of assets - Liquidation of the matrimonial economic regime",

    TPI: "INTELLECTUAL PROPERTY",
    line30: "We carry out the following procedures:",
    line31:
      "Registration of works before the Copyright Directorate of the Ministry of Culture",
    line32:
      "Applications for Industrial Property Registration before the General Directorate of the Industrial Property Registry of the Ministry of Commerce and Industries (DIGERPI)",
    line33: "Trademark registration application requirements:",
    line34:
      "Name, nationality, precise address and personal identification card or document number of the applicant and the lawyer;",
    line35:
      "If it is a legal entity, its corporate name, place of incorporation and precise address;",
    line36:
      "Name and/or design of the brand, as it will be used in the market;",
    line37:
      "Specification of the products or services on which the trademark is or will be used.",

    TOS: "OTHER SERVICES",
    line38: "Authentication and apostille of documents",
    line39: "Public registry certificates",
    line40: "Obtaining peace and safe",
    line41: "Operation notices",
    line42: "Notary deed protocol",
    line43: "Authentication of documents in a notary",
    line46: "Bank account opening",
    line44: "Contact Us ",
    line45: "in reference to other services not listed.",
    call: "Call",
  },
};
