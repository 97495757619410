import { useState, useEffect } from "react";
import { lang } from "../Functions/lang";
import DataPage from "./DataPage";
import axios from "axios";

export default function NewsPage(props) {
  const [mainLang, setMainLang] = useState(props.langCode);
  const [links, setLinks] = useState([]);

  const init = async () => {
    const payLoad = {
      code: 121,
    };
    const response = await axios
      .post(process.env.REACT_APP_DOCS_ROUTE, JSON.stringify(payLoad), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((response) => response)
      .catch((error) => {
        console.log("error axios: ", error);
      });
    console.log("response", response.data.results);
    try {
      setLinks(
        response.data.results.map((e) => {
          let tempBody = e.DESART.split("-v-");
          return {
            code: e.CODART,
            spanish: { title: e.NOMART, body: tempBody[0] },
            english: {
              title: e.URLART,
              body: tempBody[1] ?? "",
            },
          };
        })
      );
    } catch (error) {
      console.log("init error:", error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setMainLang(props.langCode);
  }, [props.langCode]);

  const interesLinks = [
    { title: lang("line2", mainLang, 3), linkUrl: "/beneficios_jubiladospty" },
    { title: lang("line3", mainLang, 3), linkUrl: "/comprar_propiedad" },
    { title: lang("line4", mainLang, 3), linkUrl: "/vender_propiedad" },
    { title: lang("line5", mainLang, 3), linkUrl: "/contrato_redactado" },
    { title: lang("line6", mainLang, 3), linkUrl: "/debida_diligencia" },
    {
      title: lang("line7", mainLang, 3),
      linkUrl: "/residencia_jubilado_pensionado",
    },
  ];

  return (
    <DataPage
      langCode={mainLang}
      title="title"
      langPack="noticias"
      subPage="noticiasInfo"
      body={`${lang(
        "line1",
        mainLang,
        "noticias"
      )}<a href="mailto:consultas@consultingpanama.com" style={{ fontWeight: "bold", textDecorationLine: "underline" }}><b>consultas@consultingpanama.com</b></a>`}
      links={links}
      handleResize={props.handleResize}
    />
    // <div className="top_page">
    //   <h1>{lang("TTIES", mainLang, 3)}</h1>
    //   <p>
    //     {lang("line1", mainLang, 3)}
    //     <a
    //       href="mailto:consultas@consultingpanama.com"
    //       style={{ fontWeight: "bold", textDecorationLine: "underline" }}
    //     >
    //       consultas@consultingpanama.com
    //     </a>
    //   </p>
    //   <ul>{links.map((item, index) => writeLines(item, index))}</ul>
    // </div>
  );
}
