import { useState, useEffect } from "react";
import "../CSS/Lawyers/procesos_civiles.css";
import { pageInfo } from "../Data/main.js";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { lang } from "../Functions/lang.js";
import BackButton from "./BackButton.js";

export default function DataPage(props) {
  const {
    links = [],
    returnPath = false,
    subPage = "",
    langCode = "",
    langPack = null,
  } = props;
  const [mainLang, setMainLang] = useState(langCode);
  const [info, setInfo] = useState("");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const title = params.get("t") ?? params.get("d") ?? props.title;
  const [txtTitle, setTxtTitle] = useState("");
  const generalInfo = {
    spanish: { title: "", body: "" },
    english: { title: "", body: "" },
  };

  const searchInfo = async (code) => {
    try {
      const payLoad = {
        code,
      };
      const response = await axios
        .post(process.env.REACT_APP_INFO_ROUTE, JSON.stringify(payLoad), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => response)
        .catch((error) => {
          console.log("error axios: ", error);
        });

      console.log("response", response);
      let finalData = response.data.results[0].DESART.split("-v-");

      if (params.get("t")) {
        if (!pageInfo[title]) {
          pageInfo[title] = {};
        }

        if (!pageInfo[title].info) {
          pageInfo[title].info = {};
        }
        pageInfo[title].info.spanish = finalData[0];
        pageInfo[title].info.english = finalData[1] ?? finalData[0];
        return pageInfo[title].info[mainLang];
      }

      if (params.get("d")) {
        generalInfo.spanish.title = response.data.results[0].NOMART;
        generalInfo.spanish.body = finalData[0];
        generalInfo.english.title = response.data.results[0].URLART;
        generalInfo.english.body = finalData[1] ?? "";
        setTxtTitle(generalInfo[mainLang].title);
        return generalInfo[mainLang].body;
      }
    } catch (error) {
      console.log("searchInfo error: ", error);
    }
    return "";
  };

  const init = async () => {
    try {
      let body;
      if (params.get("d") || props.body) {
        body = params.get("d") ? await searchInfo(params.get("d")) : props.body;
      } else {
        try {
          body = pageInfo[title].info
            ? pageInfo[title].info[mainLang]
            : await searchInfo(pageInfo[title].code);
        } catch (error0) {
          console.log("init error0: ", error0);
        }
      }

      // console.log("body", body);
      setInfo(body);
    } catch (error) {
      console.log("init error: ", error);
    }
  };

  useEffect(() => {
    console.log(title, "-", mainLang, "-", langPack, "-", returnPath);
    init();
  }, []);

  useEffect(() => {
    setMainLang(langCode);
  }, [langCode]);

  useEffect(() => {
    init();
  }, [mainLang]);

  useEffect(() => {
    props.handleResize();
  }, [info]);

  return (
    <div className="top_page">
      <h1>
        {params.get("d")
          ? txtTitle
          : lang(title, mainLang, langPack ?? returnPath)}
      </h1>
      <p dangerouslySetInnerHTML={{ __html: info }} />
      {links.length > 0 && (
        <ul>
          {links.map((e, index) => (
            <Link
              key={index}
              to={{
                pathname: `/${subPage}`,
                search: `?d=${e.code}`,
              }}
              style={{ textDecoration: "none" }}
            >
              <li>{e[mainLang].title}</li>
            </Link>
          ))}
        </ul>
      )}

      {returnPath && (
        <BackButton
          title={lang("goback", mainLang)}
          url={`/${returnPath}`}
          style={{ color: "red", backgroundColor: "blue" }}
        />
      )}
    </div>
  );
}
