import { Fragment, useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { lang } from "../Functions/lang";
import { Link, useNavigate } from "react-router-dom";
import { maindata } from "../Data/main";

import { Menu, MenuItem, MenuDivider } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

export default function Navbar(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  const navigate = useNavigate();

  const menuList = [
    { url: "", name: "home" },
    { url: "about", name: "aboutus" },
    { url: "lawyers", name: "lawyers" },
    { url: "realestate", name: "realestate" },
    { url: "interes", name: "interesttopics" },
    { url: "noticias", name: "news" },
  ];

  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);

  return (
    <nav className="navbar">
      <span className="navList">
        {menuList.map((e, index) => (
          <Fragment key={index}>
            <Link to={`/${e.url}`}>{lang(e.name, mainLang)}</Link>
            {index < menuList.length - 1 && <span>|</span>}
          </Fragment>
        ))}
      </span>
      <span className="navIcon">
        <Menu
          menuButton={
            <MenuIcon
              style={{
                color: "#7c8988",
                backgroundColor: "white",
                fontSize: "xx-large",
                borderRadius: "10%",
              }}
            />
          }
          transition
          gap={12}
          viewScroll={"close"}
          direction={"left"}
          position={"anchor"}
        >
          {menuList.map((o, index) => (
            <Fragment key={index}>
              <MenuItem
                onClick={() => {
                  navigate("/" + o.url);
                }}
              >
                {lang(o.name, mainLang)}
              </MenuItem>
              {index === 0 && <MenuDivider />}
            </Fragment>
          ))}
        </Menu>
      </span>
    </nav>
  );
}
