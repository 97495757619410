import { useState, useEffect } from "react";
import { maindata } from "../Data/main";
import { lang } from "../Functions/lang";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
// import FacebookIcon from "@mui/icons-material/Facebook";

// import {
//   faYoutube,
//   faFacebook,
//   faTwitter,
//   faInstagram
// } from "@fortawesome/free-brands-svg-icons";

export default function ContactUs(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);
  return (
    <div className="contactus-container">
      <div className="contact-box">
        <div className="contact-h2">
          {lang("contactus", mainLang).toUpperCase()}
        </div>

        <a href={`mailto:${maindata["email"]}`}>
          <EmailIcon
            className="mui-icon"
            style={{ color: "#223d3c", fontSize: "xxx-large" }}
          />
          <div>{maindata["email"]}</div>
        </a>
      </div>

      <div className="contact-h2">
        {lang("followus", mainLang).toUpperCase()}
      </div>

      <a href={maindata["instagram"]} target="blank">
        <InstagramIcon
          className="mui-icon"
          style={{ color: "#223d3c", fontSize: "xxx-large" }}
        />
        <br></br>
        {maindata["insptycons"]}
      </a>
      {/* <a href={`mailto:${maindata["email"]}`}>
				<FacebookIcon
					className="mui-icon"
					style={{ color: "#223d3c", fontSize: "xxx-large" }}
				/>
			</a> */}
    </div>

    // <div className="contactus top">
    // 	<div className="contactus title">
    // 	</div>
    // 	<div className="contactus link">
    // 	</div>
    // </div>
    // <div className="contactus bottom">
    // 	<div className="contactus title">
    // 	</div>
    // 	<div className="contactus social">
    // 	</div>
    // </div>
  );
}
