export const realestate = {
  spanish: {
    TSBRES: "SERVICIOS DE BIENES RAÍCES",
    line1: "COMPRA DE PROPIEDADES",
    line2: "VENTA DE PROPIEDADES",
    line3: "ADMINISTRACIÓN DE PROPIEDADES",
    line4: "PROGRAMACIÓN DE AVALÚOS",
    line5: "INVESTIGACIÓN DE FINCAS PARA COMPRA",
    line6: "VER PROPIEDADES",
    line7: "Alquiler de Apartamentos",
    line8: "Venta de Apartamentos",
    line9: "Alquiler de Casas",
    line10: "Venta de Casas",
  },
  english: {
    TSBRES: "REAL ESTATE SERVICES",
    line1: "PROPERTIES PURCHASE",
    line2: "PROPERTIES SELL",
    line3: "PROPERTY ADMINISTRATION",
    line4: "SCHEDULING OF APPRAISALS",
    line5: "INVESTIGATION OF PROPERTIES FOR PURCHASE",
    line6: "VIEW PROPERTIES",
    line7: "Apartments for Rent",
    line8: "Apartments for Sell",
    line9: "Houses for Rent",
    line10: "Houses for Sale",
  },
};
