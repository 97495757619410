import React from "react";
import { lang } from "../Functions/lang";
import "../CSS/Components.css";

export default function footer(props) {
  const { style = {} } = props;
  return (
    <div className="footer" style={{ ...style }}>
      {lang("copyright")} {new Date().getFullYear()}&nbsp;
    </div>
  );
}
