import React, { useState, useEffect } from "react";
import "../CSS/catalog.css";
import { maindata } from "../Data/main";
import { lang } from "../Functions/lang";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import axios from "axios";
import ModalHouse from "./ModalHouse";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F3F3F3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function AptHouse(props) {
  const { widthHeight } = props;
  const [mainLang, setMainLang] = useState(maindata.language);
  const [list, setList] = useState([]);
  const [modalImg, setmodalImg] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({});

  useEffect(() => {
    console.log(window.location.pathname); //yields: "/js" (where snippets run)
    console.log(window.location.href); //yields: "https://stacksnippets.net/js"
  }, []);

  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);

  useEffect(() => {
    props.widthHeight && setWindowDimensions({ ...widthHeight });
  }, [widthHeight]);

  const searchHouse = async (id) => {
    setList("loading");
    const payLoad = {
      id,
    };
    const response = await axios
      .post(process.env.REACT_APP_ITEM_ROUTE, JSON.stringify(payLoad), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((response) => response)
      .catch((error) => {
        console.log("error axios: ", error);
      });
    console.log("response", process.env.REACT_APP_ITEM_ROUTE, response);
    if (response) {
      setList(
        response.data.results.length > 0 ? [...response.data.results] : "empty"
      );
    }
  };

  const openModalBox = (item) => {
    setmodalImg(
      item
        ? {
            CODART: item.CODART,
            IMGART: item.IMGART,
            NOMART: item.NOMART,
            DESART: item.DESART,
            PRELTA: item.PRELTA,
          }
        : false
    );
  };

  const menuList = [
    { key: 7, code: "114" },
    { key: 8, code: "115" },
    { key: 9, code: "116" },
    { key: 10, code: "117" },
  ];

  return (
    <>
      {windowDimensions.width > 600 ? (
        <div className="wrapper2">
          {menuList.map((e, index) => (
            <p
              key={index}
              className="btn2"
              style={{
                fontSize: windowDimensions.width > 600 ? "120%" : "70%",
              }}
              onClick={() => searchHouse(e.code)}
            >
              {lang(`line${e.key}`, mainLang, 4)}
            </p>
          ))}
        </div>
      ) : (
        <>
          <div className="wrapper2">
            <div>
              {[menuList[0], menuList[1]].map((e, index) => (
                <p
                  key={index}
                  className="btn2"
                  style={{
                    fontSize: windowDimensions.width > 600 ? "120%" : "70%",
                    margin: "5px 10px",
                  }}
                  onClick={() => searchHouse(e.code)}
                >
                  {lang(`line${e.key}`, mainLang, 4)}
                </p>
              ))}
            </div>
            <div>
              {[menuList[2], menuList[3]].map((e, index) => (
                <p
                  key={index}
                  className="btn2"
                  style={{
                    fontSize: windowDimensions.width > 600 ? "120%" : "70%",
                    margin: "5px 10px",
                  }}
                  onClick={() => searchHouse(e.code)}
                >
                  {lang(`line${e.key}`, mainLang, 4)}
                </p>
              ))}
            </div>
          </div>
        </>
      )}

      <div className="img_container">
        {list === "loading" ? (
          <div style={{ textAlign: "center", height: 500 }}>
            <Dots size={30} />
          </div>
        ) : list === "empty" ? (
          <div
            style={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}
          >
            Sin resultados
          </div>
        ) : (
          <Grid
            container
            spacing={5}
            padding={2}
            columns={{ xs: 1, sm: 8, md: 20 }}
          >
            {list.map((item, index) => (
              <Grid item xs={1} sm={4} md={4} key={index}>
                <Item
                  className="houseBox"
                  onClick={() => {
                    openModalBox(item);
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      aspectRatio: "16/9",
                    }}
                  >
                    <img
                      src={
                        item.IMGART
                          ? `https://consultingpanama.com/18cats/fotos/mini/${item.CODART}.${item.IMGART}`
                          : null
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      onError={(currentTarget) => {
                        currentTarget.onerror = null;
                        currentTarget.src = null;
                      }}
                    />
                  </div>

                  <div style={{ fontSize: 18 }}>{item.NOMART}</div>
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: 16,
                      fontWeight: "bolder",
                    }}
                  >
                    B/.
                    {item.PRELTA.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </Item>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
      {modalImg && (
        <ModalHouse
          windowDimensions={windowDimensions}
          mainLang={mainLang}
          modalImg={modalImg}
          closeModal={() => {
            openModalBox(false);
          }}
        />
      )}
    </>
  );
}
