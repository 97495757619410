import React, { useState, useEffect } from "react";
import "../CSS/Components.css";
import logo from "../Images/logo.png";
// import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
// import PhoneIcon from "@mui/icons-material/Phone";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { maindata } from "../Data/main";
import { lang } from "../Functions/lang";

export default function Header({ settingLang }) {
  const [mainLang, setMainLang] = useState(maindata.language);

  useEffect(() => {
    setMainLang(localStorage.getItem("lang"));
  }, []);

  const changeLang = (_lang) => {
    if (_lang !== "" && _lang !== mainLang) {
      setMainLang(_lang);
      settingLang(_lang);
      maindata.language = _lang;
      localStorage.setItem("lang", _lang);
    }
  };
  return (
    <>
      <div className="header">
        <img className="logo" src={logo} alt="logo" />
        {/* <div</div>
				<div></div> */}
        <div className="account">
          <span
            onClick={() => {
              changeLang("spanish");
            }}
            style={{
              fontWeight: mainLang === "spanish" ? "bolder" : "",
              cursor: "pointer",
            }}
          >
            {lang("spanish", mainLang)}
          </span>{" "}
          /
          <span
            onClick={() => {
              changeLang("english");
            }}
            style={{
              fontWeight: mainLang === "english" ? "bolder" : "",
              cursor: "pointer",
            }}
          >
            {lang("english", mainLang)}
          </span>
          {/* <PersonIcon style={{ color: "#223d3c" }} />
          {lang("accountlogin", mainLang)} */}
        </div>
        <div className="maindata">
          <div>
            <EmailIcon className="mui-icon" style={{ color: "#223d3c" }} />
            {<a href={`mailto:${maindata["email"]}`}>{maindata["email"]}</a>}
          </div>
          <div>
            <EmailIcon className="mui-icon" style={{ color: "#223d3c" }} />
            {<a href={`mailto:${maindata["email2"]}`}>{maindata["email2"]}</a>}
          </div>

          {/* <div>
						<PhoneIcon className="mui-icon" style={{ color: "#223d3c" }} />
						{<a href={`tel:${maindata["phone"]}`}>{maindata["phone"]}</a>}
					</div> */}

          {/* <div>
						<WhatsAppIcon className="mui-icon" style={{ color: "#223d3c" }} />
						{
							<a href={`https://wa.me/507${maindata["whatsapp"].replace("-","")}`} target="blank">
								{maindata["whatsapp"]}
							</a>
						}
					</div> */}
        </div>
      </div>
      <div className="logo-responsive">
        <img src={logo} alt="logo" height={"auto"} width={160} />
      </div>
    </>
  );
}
