import React, { useState, useEffect } from "react";
import { lang } from "../../Functions/lang";
import { Link } from "react-router-dom";
import { maindata } from "../../Data/main";
import "../../CSS/lawyers.css";

export default function About(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);
  const writeLines = (item, index) => {
    return (
      <span key={index}>
        {lang("line" + item, mainLang, 2)}
        <br />
      </span>
    );
  };

  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);

  return (
    <div className="top_page">
      <h1>{lang("AUES", mainLang, 2)}</h1>
      <p>{[1, 2, 3, 4, 5].map((item, index) => writeLines(item, index))}</p>

      <Link to="/contactus">
        <p style={{ textDecorationLine: "underline" }}>
          <b>{[6].map((item, index) => writeLines(item, index))}</b>
        </p>
      </Link>
    </div>
  );
}
