import { useState, useEffect } from "react";
import { lang } from "../../Functions/lang.js";
import BackButton from "../../Components/BackButton.js";
import { maindata } from "../../Data/main";

export default function DebidaDiligencia(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);

  const writeLines = (item, index) => {
    return <li key={index}>{lang("line" + item, mainLang, 3)}</li>;
  };

  return (
    <div className="top_page">
      <h1>{lang("TADDES", mainLang, 3)}</h1>
      <p>{lang("line45", mainLang, 3)}</p>
      <p>{lang("line46", mainLang, 3)}</p>
      <p>{lang("line47", mainLang, 3)}</p>
      <ol type="a">
        {[48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58].map((item, index) =>
          writeLines(item, index)
        )}
      </ol>
      <p>{lang("line59", mainLang, 3)}</p>
      <ol type="1">
        {[60, 61, 62, 63].map((item, index) => writeLines(item, index))}
      </ol>
      <p>{lang("line64", mainLang, 3)}</p>
      <p>{lang("line65", mainLang, 3)}</p>
      <p>{lang("line66", mainLang, 3)}</p>
      <BackButton
        title={lang("goback", mainLang)}
        url="/interes"
        style={{ color: "red", backgroundColor: "blue" }}
      />
    </div>
  );
}
