import { useEffect, useRef, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Header from "./Components/Header";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";

import Home from "./Components/pages/Home";
import About from "./Components/pages/About";
import Realestate from "./Components/Realestate";

import Lawyers from "./Components/pages/Lawyers/Lawyers";
import DataPage from "./Components/DataPage";
import TierrasPropiedades from "./Components/pages/Lawyers/tierras_propiedades";
import DerechoFamilia from "./Components/pages/Lawyers/derecho_familia";
import PropiedadIntelectual from "./Components/pages/Lawyers/propiedad_intelectual";
import OtrosServicios from "./Components/pages/Lawyers/otros_servicios";

import ContactUs from "./Components/ContactUs";
// import Contact from "./Components/Contact";
import InteresPage from "./Components/InteresPage";
import NewsPage from "./Components/NewsPage";
import BeneficiosJubilados from "./Data/Interes/beneficios_jubiladospty";
import ComprarPropiedad from "./Data/Interes/comprar_propiedad";
import VenderPropiedad from "./Data/Interes/vender_propiedad";
import ContratoRedactado from "./Data/Interes/contrato_redactado";
import DebidaDiligencia from "./Data/Interes/debida_diligencia";
import ResidenciaJubiladoPensionado from "./Data/Interes/residencia_jubilado_pensionado";
import AptHouse from "./Components/AptHouse";
import { lang } from "./Functions/lang";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function App() {
  const location = useLocation();
  const [actLang, setActLang] = useState();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [mainHeight, setMainHeight] = useState(0);
  const ref = useRef(null);

  const handleResize = () => {
    if (ref.current?.offsetHeight) {
      setMainHeight(ref.current.offsetHeight);
    }
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    handleResize();
  }, [ref.current?.offsetHeight]);

  useEffect(() => {
    handleResize();
  }, [location]);

  useEffect(() => {
    setActLang(localStorage.getItem("lang"));

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changeLang = (_lang) => {
    setActLang(_lang);
  };

  const routesList = [
    { path: "/", elemt: <Home lang={actLang} /> },
    { path: "/about", elemt: <About lang={actLang} /> },
    { path: "/Realestate", elemt: <Realestate lang={actLang} /> },
    { path: "/lawyers", elemt: <Lawyers lang={actLang} /> },
    {
      path: "/lawyersInfo",
      elemt: (
        <DataPage
          langCode={actLang}
          returnPath="lawyers"
          handleResize={handleResize}
        />
      ),
    },
    // { path: "/derecho_familia", elemt: <DerechoFamilia lang={actLang} /> },
    // { path: "/otros_servicios", elemt: <OtrosServicios lang={actLang} /> },
    { path: "/contactus", elemt: <ContactUs lang={actLang} /> },
    // {path:"/contact", elemt: <Contact lang={actLang} />},
    {
      path: "/interes",
      elemt: <InteresPage langCode={actLang} handleResize={handleResize} />,
    },
    {
      path: "/interestInfo",
      elemt: (
        <DataPage
          langCode={actLang}
          returnPath="interes"
          handleResize={handleResize}
        />
      ),
    },
    {
      path: "/noticias",
      elemt: <NewsPage langCode={actLang} handleResize={handleResize} />,
    },
    {
      path: "/noticiasInfo",
      elemt: (
        <DataPage
          langCode={actLang}
          returnPath="noticias"
          handleResize={handleResize}
        />
      ),
    },
    // {
    //   path: "/beneficios_jubiladospty",
    //   elemt: <BeneficiosJubilados lang={actLang} />,
    // },
    {
      path: "/comprar_propiedad",
      elemt: <ComprarPropiedad lang={actLang} />,
    },
    { path: "/vender_propiedad", elemt: <VenderPropiedad lang={actLang} /> },
    {
      path: "/contrato_redactado",
      elemt: <ContratoRedactado lang={actLang} />,
    },
    {
      path: "/debida_diligencia",
      elemt: <DebidaDiligencia lang={actLang} />,
    },
    {
      path: "/residencia_jubilado_pensionado",
      elemt: <ResidenciaJubiladoPensionado lang={actLang} />,
    },
    {
      path: "/apt_house",
      elemt: <AptHouse lang={actLang} widthHeight={windowDimensions} />,
    },
  ];

  return (
    <>
      <main ref={ref}>
        <Header settingLang={changeLang} />
        <Navbar lang={actLang} />
        <Routes>
          {routesList.map((e, index) => (
            <Route key={index} path={e.path} element={e.elemt} />
          ))}
        </Routes>
        <Footer
          style={{ opacity: mainHeight < windowDimensions.height ? 0 : 1 }}
        />
      </main>
      {mainHeight < windowDimensions.height && (
        <Footer style={{ position: "fixed", bottom: 0 }} />
      )}
    </>
  );
}
