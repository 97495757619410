export const maindata = {
  name: "Consulting Panama",
  email: "info@consultingpanama.com",
  email2: "consultas@consultingpanama.com",
  phone: "6679-4057",
  whatsapp: "6332-4253",
  language: "spanish",
  instagram: "https://www.instagram.com/panama_consulting/",
  insptycons: "panama_consulting",
};

export const language = {
  spanish: {
    accountlogin: "Ingrese a su Cuenta",
    home: "INICIO",
    aboutus: "SOBRE NOSOTROS",
    legalpractice: "ÁREAS DE PRÁCTICA LEGAL",
    realestate: "BIENES RAÍCES",
    interesttopics: "TEMAS DE INTERÉS",
    news: "NOTICIAS",
    lawyers: "SERVICIOS LEGALES",
    ourservices: "Nuestros Servicios",
    housescatalog: "Catálogo de Casas",
    apartmentscatalog: "Catálogo de Apartamentos",
    copyright: "Copyright",
    contactus: "Contáctenos",
    goback: "Regresar",
    english: "Inglés",
    spanish: "Español",
    followus: "Síguenos",
  },
  english: {
    accountlogin: "Log Into Your Account",
    home: "HOME",
    aboutus: "ABOUT US",
    legalpractice: "LEGAL PRACTICE AREAS",
    realestate: "REAL ESTATE",
    interesttopics: "TOPICS OF INTEREST",
    news: "NEWS",
    lawyers: "LAWYERS",
    ourservices: "Our Services",
    housescatalog: "Houses",
    apartmentscatalog: "Apartments",
    copyright: "Copyright",
    contactus: "Contact us",
    goback: "Go back",
    english: "English",
    spanish: "Spanish",
    followus: "Follow us",
  },
};

export const lawyersMenu = [
  "procesosCiviles",
  "derechoMigratorio",
  "propiedadHorizontal",
  "tierrasPropiedades",
  "derechoFiscal",
  "derechoFamilia",
  "propiedadIntelectual",
  "derechoCoorporativo",
  "otrosServicios",
];

export const pageInfo = {
  procesosCiviles: { code: 2, returnPath: "lawyers" },
  derechoMigratorio: { code: 9, returnPath: "lawyers" },
  propiedadHorizontal: { code: 10, returnPath: "lawyers" },
  tierrasPropiedades: { code: 5, returnPath: "lawyers" },
  derechoFiscal: { code: 11, returnPath: "lawyers" },
  derechoFamilia: { code: 6, returnPath: "lawyers" },
  propiedadIntelectual: { code: 7, returnPath: "lawyers" },
  derechoCoorporativo: { code: 12, returnPath: "lawyers" },
  otrosServicios: { code: 8, returnPath: "lawyers" },
};

export const interestInfo = [];
