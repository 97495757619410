import { useState, useEffect } from "react";
import { lang } from "../../Functions/lang.js";
import BackButton from "../../Components/BackButton.js";
import { maindata } from "../../Data/main";

export default function VenderPropiedad(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);

  const writeLines = (item, index) => {
    return <li key={index}>{lang("line" + item, mainLang, 3)}</li>;
  };

  return (
    <div className="top_page">
      <h1>{lang("TQHVPES", mainLang, 3)}</h1>
      <p>{lang("line29", mainLang, 3)}</p>
      <ol>
        {[30, 31, 32, 33, 34, 35, 36, 37, 38].map((item, index) =>
          writeLines(item, index)
        )}
      </ol>
      <BackButton
        title={lang("goback", mainLang)}
        url="/interes"
        style={{ color: "red", backgroundColor: "blue" }}
      />
    </div>
  );
}
