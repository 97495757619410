import { useState, useEffect } from "react";
import { lang } from "../../Functions/lang.js";
import BackButton from "../../Components/BackButton.js";
import { maindata } from "../../Data/main";

export default function ComprarPropiedad(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);

  const writeLines = (item, index) => {
    return <li key={index}>{lang("line" + item, mainLang, 3)}</li>;
  };

  return (
    <div className="top_page">
      <h1>{lang("TQHCPES", mainLang, 3)}</h1>
      <p>{lang("line22", mainLang, 3)}</p>
      <ol>
        {[23, 24, 25, 26, 27, 28].map((item, index) => writeLines(item, index))}
      </ol>
      <BackButton
        title={lang("goback", mainLang)}
        url="/interes"
        style={{ color: "red", backgroundColor: "blue" }}
      />
    </div>
  );
}
