import { useState, useEffect } from "react";
import { lang } from "../../Functions/lang.js";
import { Link } from "react-router-dom";
import BackButton from "../../Components/BackButton.js";
import { maindata } from "../../Data/main";

export default function ResidenciaJubiladoPensionado(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);

  const writeLines = (item, index) => {
    return <p key={index}>{lang("line" + item, mainLang, 3)}</p>;
  };

  const writeol = (item, index) => {
    return <li key={index}>{lang("line" + item, mainLang, 3)}</li>;
  };
  return (
    <div className="top_page">
      <h1>{lang("TPRJPES", mainLang, 3)}</h1>
      <p>{lang("line67", mainLang, 3)}</p>
      <ol type="1">
        {[68, 69, 70].map((item, index) => writeol(item, index))}
      </ol>

      <p>
        {[71, 72, 73, 74, 75, 76].map((item, index) => writeLines(item, index))}
      </p>

      <span>{lang("line77", mainLang, 3)}</span>
      <Link
        to="/contactus"
        style={{ fontWeight: "bold", textDecorationLine: "underline" }}
      >
        <span>{lang("line78", mainLang, 3)}</span>
      </Link>
      <span>{lang("line79", mainLang, 3)}</span>
      <BackButton
        title={lang("goback", mainLang)}
        url="/interes"
        style={{ color: "red", backgroundColor: "blue" }}
      />
    </div>
  );
}
