import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "react-modal";
import axios from "axios";
import "../CSS/catalog.css";

export default function ModalHouse(props) {
  const { modalImg, windowDimensions, closeModal, mainLang } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [bigImage, setBigImage] = useState("");
  const [rows, setRows] = useState([]);
  const checkVertical = () => {
    return windowDimensions.width < windowDimensions.height ? true : false;
  };

  const loadData = async () => {
    const payLoad = {
      id: modalImg.CODART,
    };
    const response = await axios
      .post(process.env.REACT_APP_ITEMINFO_ROUTE, JSON.stringify(payLoad), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((response) => response)
      .catch((error) => {
        console.log("error axios: ", error);
      });
    console.log("response", response.data);
    if (response && response.data) {
      if (response.data.images) {
        setImages([...response.data.images]);
      }
      if (response.data.spec) {
        setRows([...response.data.spec]);
      }
    }
  };

  const changeImage = (index) => {
    setBigImage(
      `${modalImg.CODART}${
        images[index].CODIMG === 0
          ? ""
          : images[index].CODIMG < 10
          ? "_0" + images[index].CODIMG
          : "_" + images[index].CODIMG
      }.${images[index].FORIMG}`
    );
  };

  useEffect(() => {
    setIsOpen(modalImg ? true : false);
    setBigImage(modalImg ? `${modalImg.CODART}.${modalImg.IMGART}` : "");
    loadData();
  }, [modalImg]);

  return (
    <Modal
      isOpen={isOpen}
      //onAfterOpen={afterOpenModal}
      //onRequestClose={closeModal}
      //style={customStyles}
      ariaHideApp={false}
      contentLabel="Modal"
      style={{
        content: {
          display: "flex",
          flexDirection: checkVertical() ? "column" : "row",
        },
      }}
    >
      <div
        style={{
          position: "relative",
          flex: checkVertical() ? 0.4 : 0.55,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <div
            style={{
              height: checkVertical() ? 180 : windowDimensions.height - 200,
              width: "100%",
            }}
          >
            <img
              alt={`${modalImg.CODART}.${modalImg.IMGART}`}
              src={
                modalImg.IMGART
                  ? `https://consultingpanama.com/18cats/fotos/grande/${bigImage}`
                  : null
              }
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              onError={(currentTarget) => {
                currentTarget.onerror = null;
                currentTarget.src = null;
              }}
            />
          </div>
        </div>

        <div
          style={{
            position: "relative",
            width: "100%",
            overflowX: "auto",
            overflowY: "hidden",
            height: 70,
          }}
        >
          <table align="center">
            <tr>
              {images.map((e, index) => (
                <td key={index}>
                  <div
                    style={{
                      width: checkVertical() ? 70 : 100,
                      height: checkVertical() ? 50 : 70,
                    }}
                  >
                    <img
                      onClick={() => {
                        changeImage(index);
                      }}
                      alt={`${modalImg.CODART}${
                        e.CODIMG === 0
                          ? ""
                          : e.CODIMG < 10
                          ? "_0" + e.CODIMG
                          : "_" + e.CODIMG
                      }.${e.FORIMG}`}
                      src={
                        modalImg.IMGART
                          ? `https://consultingpanama.com/18cats/fotos/mini/${
                              modalImg.CODART
                            }${
                              e.CODIMG === 0
                                ? ""
                                : e.CODIMG < 10
                                ? "_0" + e.CODIMG
                                : "_" + e.CODIMG
                            }.${e.FORIMG}`
                          : null
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      onError={(currentTarget) => {
                        currentTarget.onerror = null;
                        currentTarget.src = null;
                      }}
                    />
                  </div>
                </td>
              ))}
            </tr>
          </table>
        </div>
      </div>
      <div
        style={{
          flex: checkVertical() ? 0.6 : 0.45,
          padding: 10,
          overflow: "auto",
          paddingTop: 20,
        }}
      >
        <div
          style={{
            fontSize: 20,
            fontWeight: "bolder",
            backgroundColor: "#F0F0F0",
            padding: 5,
          }}
        >
          {modalImg.NOMART}
        </div>
        {"B/. " +
          modalImg.PRELTA.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        {modalImg.DESART && (
          <div dangerouslySetInnerHTML={{ __html: modalImg.DESART }}></div>
        )}
        <table align="center">
          {rows.map((e, index) => (
            <tr>
              <td style={{ minWidth: 130, backgroundColor: "#e7e7e7" }}>
                {mainLang === "spanish" ? e.ESP : e.ING}
              </td>
              <td align="center" style={{ minWidth: 30 }}>
                {e.DATESA}
              </td>
            </tr>
          ))}
        </table>
      </div>

      <div
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: "white",
          borderRadius: 40,
          width: 40,
          height: 40,
        }}
      >
        <CancelIcon
          className="mui-icon"
          style={{ color: "red", fontSize: 40 }}
          onClick={closeModal}
        />
      </div>
    </Modal>
  );
}
