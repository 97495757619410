import { language } from "../Data/main";
import { lawyers } from "../Data/lawyers";
import { aboutus } from "../Data/about";
import { interes } from "../Data/Interes/interes";
import { noticias } from "../Data/noticias";
import { realestate } from "../Data/realestate";

export const lang = (word, newLang = "spanish", _type = 0) => {
  if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", "spanish");
  }
  if (newLang === "") {
    newLang = localStorage.getItem("lang");
  }
  try {
    switch (_type) {
      case 0:
        return language[newLang][word];
      case 1:
        return lawyers[newLang][word];
      case 2:
        return aboutus[newLang][word];
      case 3:
        return interes[newLang][word];
      case 4:
        return realestate[newLang][word];
      case "lawyers":
        return lawyers[newLang][word];
      case "interes":
        return interes[newLang][word];
      case "noticias":
        return noticias[newLang][word];
      default:
        break;
    }
  } catch (error) {
    console.log("lang error: ", error);
  }
  return word;
};
