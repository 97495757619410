export const aboutus = {
  spanish: {
    AUES: "SOBRE NOSOTROS",
    line1:
      "Consulting Panama está integrado por un grupo de abogados y entre ellos un corredor de bienes raíces, colegas que mantienen una relación de respeto, altos principios y valores que le dan forma a los servicios que con gusto le brindamos a nuestros clientes.",
    line2:
      "Le gustará saber que, para mayor claridad y protección mutua, al iniciar una relación abogado-cliente dejamos por escrito (mediante contrato o proforma dependiendo del caso), los servicios que usted tenga a bien contratar y los honorarios pactados para la ejecución de los mismos.",
    line3:
      "A nuestro equipo también le interesa el medio ambiente, es por eso que mantenemos nuestros archivos en formato electrónico y solamente utilizamos documentación en papel cuando el trámite lo amerita o usted, nuestro cliente, lo solicite. El formato electrónico también nos permite mantenerle al tanto del progreso de su proceso de una manera más eficiente.",
    line4:
      "Nuestra misión es brindarle un servicio eficiente, profesional y sobre todo confiable, de acuerdo a sus necesidades y siempre dentro del marco de la ley, con el fin de que usted se sienta satisfecho con nuestro manejo y nuestra relación sea transparente y duradera.",
    line5: "¡Será un placer trabajar con usted!",
    line6: "Contáctenos aquí",
  },

  english: {
    AUES: "ABOUT US",
    line1:
      "Consulting Panama is made up of a group of lawyers and among them a real estate broker, colleagues who maintain a relationship of respect, high principles and values that shape the services that we gladly provide to our clients.",
    line2:
      "You will like to know that, for greater clarity and mutual protection, when starting an attorney-client relationship we establish through a written quote or contract depending on the case, the services that you wish to contract and the fees agreed upon for their execution.",
    line3:
      "Our team is also interested in the environment, which is why we maintain our files in electronic format and only use paper documentation when the procedure warrants it or when you, our client, request it. The electronic format also allows us to keep you informed of the progress of your process in a more efficient way.",
    line4:
      "Our mission is to provide you with an efficient, professional and above all reliable service, according to your needs and always within the framework of the law, so that you feel satisfied with our management and our relationship is transparent and lasting.",
    line5: "It will be a pleasure to work with you!",
    line6: "Contact us here.",
  },
};
