import { useState, useEffect } from "react";
import "../../CSS/Components.css";
import "../../CSS/services.css";
import "../../CSS/catalog.css";
//import Carousel from "nuka-carousel";
import { lang } from "../../Functions/lang";
import { Link } from "react-router-dom";
import { maindata } from "../../Data/main";

import a from "../../Images/carrousel/a.jpg";
import b from "../../Images/carrousel/b.jpg";
import c from "../../Images/carrousel/c.jpg";
import d from "../../Images/carrousel/d.jpg";
import e from "../../Images/carrousel/e.jpg";
import f from "../../Images/carrousel/f.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function Home(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);
  return (
    <>
      <Carousel
        autoPlay={true}
        infiniteLoop
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
      >
        {[a, b, c, d, e, f].map((value, index) => {
          return (
            <div key={index}>
              <img src={value} alt="" />
            </div>
          );
        })}
      </Carousel>

      <div className="wrapper">
        <Link to={"/lawyers"} style={{ textDecoration: "none" }}>
          <div className="btn">{lang("lawyers", mainLang)}</div>
        </Link>
        <Link to={"/Realestate"} style={{ textDecoration: "none" }}>
          <div className="btn">{lang("realestate", mainLang)}</div>
        </Link>
        <Link to={"/contactus"} style={{ textDecoration: "none" }}>
          <div className="btn">{lang("contactus", mainLang)}</div>
        </Link>
      </div>
    </>
  );
}
