import React, { useState, useEffect } from "react";
import { lang } from "../../../Functions/lang.js";
import { Link } from "react-router-dom";
import { lawyersMenu, maindata } from "../../../Data/main.js";

export default function Lawyers(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);

  return (
    <div className="top_page">
      <h1>{lang("title", mainLang, "lawyers")}</h1>
      <div className="law_btns_page">
        {lawyersMenu.map((item, index) => (
          <div className="law_btns_links" key={index}>
            <Link
              key={index}
              to={{
                pathname: "/lawyersInfo",
                search: `?t=${item}`,
              }}
              style={{ textDecoration: "none" }}
            >
              <div>{lang(item, mainLang, "lawyers")}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
