import { useState, useEffect } from "react";
import { lang } from "../../Functions/lang.js";
import BackButton from "../../Components/BackButton.js";
import { maindata } from "../../Data/main";

export default function ContratoRedactado(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);

  const writeLines = (item, index) => {
    return <p>{lang("line" + item, mainLang, 3)}</p>;
  };

  return (
    <div className="top_page">
      <h1>{lang("TICBRES", mainLang, 3)}</h1>
      <p>
        {[39, 40, 41, 42, 43, 44].map((item, index) => writeLines(item, index))}
      </p>
      <BackButton
        title={lang("goback", mainLang)}
        url="/interes"
        style={{ color: "red", backgroundColor: "blue" }}
      />
    </div>
  );
}
