export const noticias = {
  spanish: {
    title: "NOTICIAS",
    line1:
      "AVISO LEGAL. Este sitio contiene información general y no debe considerarse en ningún caso como asesoramiento para su caso particular. Para consultas y asesoramiento, sírvase escribirnos a ",
    line2: "BENEFICIOS PARA JUBILADOS EN PANAMÁ",
    line3: "¿QUÉ DEBO HACER ANTES DE COMPRAR UNA PROPIEDAD EN PANAMÁ?",
    line4: "¿QUÉ DEBO HACER ANTES DE VENDER UNA PROPIEDAD EN PANAMÁ?",
    line5: "LA IMPORTANCIA DE UN CONTRATO BIEN REDACTADO",
    line6:
      "MI ABOGADO ME PRACTICA UNA “DEBIDA DILIGENCIA”, ¿A QUÉ SE REFIERE? Y ¿POR QUÉ ES IMPORTANTE?",
    line7: "PERMISO DE RESIDENCIA EN CALIDAD DE JUBILADO Y PENSIONADO",

    TBJES: "BENEFICIOS PARA JUBILADOS EN PANAMÁ",
    line8:
      "Ley 6 de 16 de junio de 1987 establece beneficios aplicables para los ciudadanos y residentes jubilados, pensionados y de tercera edad, es decir los ciudadanos de 55 años en adelante en el caso de las mujeres, y de 60 años o más en el de los varones. Conozca algunos a continuación:",
    line9:
      "Descuento de 50% por la entrada a actividades de recreación y entretenimiento, tales como cines, teatros, deportes y otros, salvo excepciones.",
    line10:
      "Descuento en la tarifa de transporte público aplicado así: Autobuses trenes y barcos, 30%; y 25% en pasajes aéreos.",
    line11:
      "Un descuento mínimo en los precios regulares de hoteles, moteles y pensiones así: 50% de lunes a jueves, y 30% los días viernes, sábados y domingos.",
    line12:
      "Descuento de 25% del valor del consumo individual de comida en cualquier restaurante, excepto en fondas.",
    line13: "Descuento de 15% en los establecimientos de comidas rápidas.",
    line14:
      "Descuento de 15% de la cuenta total por servicios de hospitales y clínicas privadas.",
    line15: "Descuento en farmacias de 10% en medicamentos recetados.",
    line16:
      "Descuento en los siguientes servicios médicos así: 20 % en los honorarios por consultas médicas y quirúrgicas; 15 % por servicios odontológicos; y 15% por servicios de optometría.",
    line17:
      "Descuento de 50% de los gastos o comisión de cierre en la transacciones y descuento de 15% en la tasa de interés máximo que la ley le permita cobrar a bancos, financieras, cooperativas e instituciones de crédito en préstamos personales y comerciales a su nombre.",
    line18: "1% menos en hipotecas para vivienda de uso personal.",
    line19:
      "Descuento de 25% en la facturación del consumo mensual de energía eléctrica, de entidad pública o privada, hasta 600 kWh.",
    line20:
      "Descuento de 25% de la tarifa por consumo de agua, siempre que no sea mayor de treinta balboas, la cuenta sea residencial, constituya su vivienda y esté a su nombre.",
    line21:
      "Estos beneficios aplican de igual manera a los extranjeros que cuenten con ",
    line80: "Permiso de Residencia en calidad de Jubilado y Pensionado.",

    TQHCPES: "¿QUÉ DEBO HACER ANTES DE COMPRAR UNA PROPIEDAD EN PANAMÁ?",
    line22:
      "Antes de comprar su próxima casa, apartamento o terreno, sea este para residir o como negocio o inversión, primero contáctenos, esto es lo que haremos por usted:",
    line23:
      "A fin de determinar el área de búsqueda, hay que tomar nota de sus necesidades y preferencias;",
    line24:
      "Si ya sabe en qué área desea vivir o si ya ha visto una propiedad que piensa que le gustaría adquirir, permítanos investigar primero el estatus legal de la propiedad, con el fin de determinar si la propiedad está libre de restricciones, gravámenes o defectos insubsanables; además realizamos la diligencia de asistirle en contrataciones de inspectores estructurales y demás que certifiquen la seguridad y el estado real del bien que está por adquirir, o que sencillamente le puedan dar un presupuesto de la inversión requerida en reparaciones si decide comprar el bien en su estado actual (as is).",
    line25:
      "Toda transacción exitosa inicia con un contrato meticuloso que proteja y asegure sus intereses y contenga la voluntad de las partes, dependiendo de la forma de compra, redactaremos para usted un Contrato Promesa de Compraventa o bien un Contrato de Compraventa.",
    line26:
      "Una vez que el vendedor haya realizado el pago de sus impuestos de venta, le asistiremos en el proceso de Confección de sus escrituras y la correspondiente inscripción en el Registro Público de Panamá.",
    line27:
      "Inscrita su propiedad, realizaremos la actualización de nuevo propietario ante DGI y ANATI.",
    line28:
      "Habiendo concluido su proceso de compra, si se trata esta de su vivienda principal, le ofrecemos nuestros servicios para que su casa o apartamento queden registrados en la Dirección General de Ingresos como Patrimonio Familiar Tributario o Vivienda Principal, según aplique. Estamos a la orden para aclarar sus dudas a este respecto.",

    TQHVPES: "¿QUÉ DEBO HACER ANTES DE VENDER UNA PROPIEDAD EN PANAMÁ?",
    line29:
      "Antes de vender su bien inmueble sea este una residencia, local comercial o terreno, primero contáctenos, esto es lo que haremos por usted:",
    line30:
      "Realizaremos una visita guiada por usted para conocer su inmueble y tomar fotos y videos para publicidad.",
    line31:
      "Verificaremos el estado legal de la propiedad con el fin de asegurar que está lista para la venta.",
    line32:
      "Si no cuenta aún con un avalúo actualizado, le asistimos en la programación de un avalúo a cargo de una reconocida casa valuadora aceptada por los bancos del país.",
    line33:
      "Habiendo cumplido los pasos anteriores, podremos concretar el precio por el cual usted desea vender (márgenes de venta alto y bajo) y procederemos a publicitar su propiedad a nuestras expensas en todo el territorio nacional y con nuestro corredor asociado en los Estados Unidos.",
    line34:
      "Cuando se reciban ofertas por su inmueble se le avisará inmediatamente para que usted exprese su aceptación o rechazo por la misma.",
    line35:
      "Aceptada una oferta, procedemos a la parte de la elaboración de los contratos. Dependiendo de si su propiedad se encuentra hipotecada y debe ser liberada de la misma, o de si el comprador utilizará esta figura para adquirir el bien, redactaremos para usted un Contrato Promesa de Compraventa o bien un Contrato de Compraventa que proteja y asegure sus intereses y contenga el Acuerdo de voluntad de las partes envueltas en la transacción.",
    line36:
      "El próximo paso es el cálculo y pago de los intereses de venta, a saber: El Impuesto de Transferencia de Bien Inmueble que consiste en el 2% sobre el valor que sea mayor entre el precio de venta y el valor catastral inscrito; y el Impuesto de Ganancia de Capital que grava con el 3% la ganancia de capital por la enajenación del bien inmueble.",
    line37:
      "Presentadas y pagadas las declaraciones de impuestos, procedemos a protocolizar el acto en la Notaria.",
    line38:
      "Levantada la escritura, y habiendo obtenido los Paz y Salvos de DGI, IDAAN y ASEO, procedemos al registro de la misma en el Registro Público de Panamá. Una vez inscrita la venta, y dependiendo de lo acordado mediante contrato, se cancela el saldo insoluto del precio de venta y concluye exitosamente su transacción. Hasta la próxima venta!",

    TICBRES: "LA IMPORTANCIA DE UN CONTRATO BIEN REDACTADO",
    line39:
      "Un contrato es un acuerdo entre las partes que resulta en obligaciones y derechos. No se debe tomar a la ligera la confección y firma de un contrato. Aunque es imposible contemplar dentro de un contrato cada posible variable dentro de una situación, a idea es dentro de lo posible llegar a un equilibrio mediante alinear las voluntades en terreno común, en busca de un beneficio y de protección para las partes otorgantes.",
    line40:
      "Antes de adquirir una propiedad, consulte a su abogado a fin de que realice la debida diligencia sobre el título de propiedad y se asegure que el mismo se encuentra libre de vicios que puedan entorpecer la transacción.",
    line41:
      "Luego, su abogado elaborará un Contrato de promesa de compra-venta que contemplará las condiciones de la compra, y le guiará por el proceso de compra-venta.",
    line42:
      "Cuando venda una propiedad, tenga en cuenta que, salvo distinto acuerdo (establecido mediante contrato), el vendedor ha de declarar y pagar el impuesto de Transferencia (2%) sobre el valor de la propiedad y el Impuesto sobre las ganancias de Capital (3%) sobre lo que sea mayor entre el valor catastral y el precio de venta. Este tipo de asuntos se establecen en los contratos. Asimismo, se establecen derechos y deberes de las partes, formas y montos de pagos y compensaciones, duración, penalizaciones, causales de nulidad o recisión y todo otro detalle que pueda mitigar posteriores conflictos de intereses o posibles incumplimientos.",
    line43:
      "Aún cuando se trate de contratos válidamente “otorgados en país extranjero para cumplirse en Panamá”, sus efectos “se arreglarán a las leyes panameñas”, como bien estipula nuestro Código Civil(Art.6).",
    line44:
      "Existen otros tipos de contrato, contratos de trabajo, contratos matrimoniales, de arrendamiento, derechos de propiedad intelectual, contratos de suministros y muchos más, pero en cada caso, confíe a profesionales la elaboración de sus contratos, a fin de que su voluntad se establezca claramente y sus intereses queden bien protegidos. ¡Nos distinguimos por nuestra transparencia en todos nuestros tratos!",

    TADDES:
      "MI ABOGADO ME SOLICITA INFORMACIÓN PARA UNA “DEBIDA DILIGENCIA”, ¿A QUÉ SE REFIERE? Y ¿POR QUÉ ES IMPORTANTE?",
    line45:
      "La Ley 23 de 27 de abril de 2015 define Debida Diligencia como el “Conjunto de normas, de políticas, de procedimientos, de procesos y de gestiones que permitan un conocimiento razonable de los aspectos cualitativos y cuantitativos del cliente y del beneficiario final, con especial atención del perfil financiero y transaccional del cliente, el origen de su patrimonio y el seguimiento continuo de sus transacciones u operaciones, cuando aplique, conforme a la reglamentación de esta Ley, por parte de cada organismo de supervisión”.",
    line46:
      "Se hace énfasis adicional a que “los sujetos obligados no financieros deberán obtener información y documentación relacionada con el perfil financiero y transaccional de sus clientes” (Art. 26B) y que deben asimismo “Conocer la naturaleza del negocio del cliente” (Artículo 38). Estos “sujetos obligados” han de proporcionar dicha información a la Superintendencia en caso de ser requerida. ¿Quiénes son los sujetos obligados no financieros?",
    line47:
      "Son Sujetos obligados no financieros los supervisados por la Superintendencia de sujetos no financieros definidos en el artículo 40 de Ley 124 de 2020, entre ellos se encuentran los Abogados cuando en ejercicio de su actividad profesional realicen en nombre de un cliente o por un cliente alguna de las actividades sujetas a supervisión, tales como: ",
    line48: "Compraventa de inmuebles.",
    line49:
      "Administración de dinero, valores bursátiles y otros activos del cliente.",
    line50: "Administración de cuentas bancarias, de ahorro o valores.",
    line51:
      "Organización de aportes o contribuciones para la creación, operación o administración de personas jurídicas.",
    line52:
      "Creación, operación o administración de personas jurídicas o estructuras jurídicas, como fundaciones de interés privado, sociedades anónimas, fideicomisos y demás.",
    line53: "Compraventa de personas jurídicas o estructuras jurídicas.",
    line54:
      "Actuación o arreglo para que una persona actúe como director o apoderado de una persona jurídica o una posición similar, en relación con otras personas jurídicas.",
    line55:
      "Proveer de un domicilio registrado, domicilio comercial o espacio físico, domicilio postal o administrativo a una persona jurídica o estructura jurídica que no sea de su propiedad.",
    line56:
      "Actuación o arreglo para que una persona actúe como accionista para una persona jurídica.",
    line57:
      "Actuación o arreglo para que una persona actúe como participante de un fideicomiso expreso o que desempeñe la función equivalente para otra forma de estructura jurídica.",
    line58:
      "Cuando prestan los servicios y actividades propias del agente residente de personas jurídicas constituidas o registradas de conformidad con las leyes de la República de Panamá.",
    line59:
      "Es de suma importancia comprender que antes de iniciar la relación cliente-abogado, el cliente debe proveernos la siguiente documentación con el fin de cumplir con esta ley:",
    line60: "Documentos de Identificación.",
    line61:
      "Información y documentación relacionada con su perfil financiero y transaccional.",
    line62:
      "Documentación que nos permita conocer la Naturaleza de su negocio.",
    line63: "Mantener toda esta información actualizada.",
    line64:
      "Se establecen sanciones desde B/.5,000.00 hasta B/.5,000,000.00 a los sujetos obligados por el incumplimiento de esta norma. También la suspensión de los derechos corporativos de la persona jurídica relacionada con el incumplimiento.",
    line65:
      "Se establecen asimismo sanciones desde B/.5,000.00 hasta B/.100,000.00 a los clientes que no cumplan con entregar toda la documentación e información requerida dentro del plazo establecido.",
    line66:
      "Por ello, habiendo resumido estos puntos, puede estar seguro de que su abogado le solicita esta información en cumplimiento de la legislación vigente sobre prevención de blanqueo de capitales el financiamiento del terrorismo y el financiamiento de la proliferación de armas de destrucción masiva, y que es muy importante facilitar toda documentación requerida y mantenerla actualizada en el mejor cumplimiento de la Ley y a fin de proteger su negocio y evitar sanciones y suspensiones.",

    TPRJPES: "PERMISO DE RESIDENCIA EN CALIDAD DE JUBILADO Y PENSIONADO",
    line67:
      "Jubilarse en Panamá es cada vez más conveniente. El permiso de residencia en calidad de Jubilado y Pensionado otorga residencia permanente sin necesidad de solicitar prórroga y el mismo cuenta con atractivos beneficios como lo son:",
    line68:
      "Exoneración del pago de cualquier depósito, gravamen o derecho migratorio en relación con la obtención del Permiso de Jubilado y Pensionado.",
    line69:
      "Una exención única de impuestos sobre la importación de artículos de uso doméstico o personal (hasta $10,000).",
    line70:
      "Una exención arancelaria total cada dos años cuando se importe un automóvil para uso personal o familiar.",
    line71:
      "Además, tienen derecho a otros beneficios para jubilados en Panamá, conozca algunos aquí.",
    line72:
      "“Podrán solicitar este permiso el extranjero que reciban jubilación o pensión por parte de gobierno extranjero, organismo internacional o empresa privada, que ingresen al territorio nacional para radicarse en él y cuenta con suficientes medios económicos para sufragar todos sus gastos de subsistencia y los de sus dependientes en el país. La renta o pensión mensual no podrá ser inferior a mil balboas (B/.1,000.00) y debe estar concedida en forma vitalicia”.",
    line73:
      "En el caso de que el solicitante haya adquirido una propiedad a título personal en el territorio nacional por una suma superior a los cien mil balboas (B/.100,000.00), la pensión requerida podrá ser por un mínimo de setecientos cincuenta balboas (B/ 750.00).",
    line74:
      "En los casos de los conyugues se podrá optar por acreditar las sumas de ambos para cumplir con el mínimo de la pensión establecida que es de mil balboas (B/.1,000.00).",
    line75:
      "En los casos de hijos dependientes, su permiso será temporal hasta que cumplan veinticinco (25) años siempre y cuando prueben que realizan estudios completos, no obstante, no tendrán derecho a la permanencia ni a la condición de pensionado. A excepción de aquellos hijos dependientes que sufran una discapacidad profunda comprobada.",
    line76: "Este Permiso se otorga de forma indefinida.",
    line77: "Si desea retirarse en Panamá, ",
    line78: "contáctenos",
    line79:
      " podemos explicarle todo el proceso y acompañarle en cada etapa. Bienvenido a Panamá! ",
  },

  english: {
    title: "NEWS",
    line1:
      "LEGAL NOTICE. This site contains general information and should in no way be considered advice for your particular case. For questions and advice, please write to us at ",
    line2: "BENEFITS FOR PENSIONERS IN PANAMA",
    line3: "WHAT SHOULD I DO BEFORE BUYING A PROPERTY IN PANAMA?",
    line4: "WHAT SHOULD I DO BEFORE SELLING A PROPERTY IN PANAMA?",
    line5: "THE IMPORTANCE OF A WELL-DRAWN CONTRACT",
    line6:
      "MY LAWYER DOES “DUE DILIGENCE” ON ME, WHAT DOES THIS REFER TO? AND WHY IS IT IMPORTANT?",
    line7: "RESIDENCE PERMIT AS A RETIRED AND PENSIONER",

    TBJES: "BENEFITS FOR RETIRED IN PANAMA",
    line8:
      "Law 6 of June 16, 1987 establishes benefits applicable to retired, pensioned, and elderly citizens and residents, that is, citizens aged 55 and older in the case of women, and 60 years or older in the case of women. men. Get to know some below:",
    line9:
      "50% discount for admission to recreation and entertainment activities, such as cinemas, theaters, sports and others, with exceptions.",
    line10:
      "Discount on public transportation fare applied as follows: Buses, trains and boats, 30%; and 25% on air tickets.",
    line11:
      "A minimum discount on the regular prices of hotels, motels and guesthouses as follows: 50% from Monday to Thursday, and 30% on Fridays, Saturdays and Sundays.",
    line12:
      "25% discount on the value of individual food consumption in any restaurant, except in inns.",
    line13: "15% discount in fast food establishments.",
    line14:
      "15% discount on the total bill for services from hospitals and private clinics.",
    line15: "10% discount in pharmacies on prescription medications.",
    line16:
      "Discount on the following medical services: 20% on fees for medical and surgical consultations; 15% for dental services; and 15% for optometry services.",
    line17:
      "50% discount on closing expenses or commission on transactions and 15% discount on the maximum interest rate that the law allows you to charge banks, finance companies, cooperatives and credit institutions on personal and commercial loans in your name.",
    line18: "1% less on mortgages for housing for personal use.",
    line19:
      "25% discount on the billing of monthly electricity consumption, from a public or private entity, up to 600 kWh.",
    line20:
      "25% discount on the rate for water consumption, as long as it is not more than thirty balboas, the account is residential, constitutes your home and is in your name.",
    line21: "These benefits apply equally to foreigners who have ",
    line80: "Residence permit as a retired and pensioner.",

    TQHCPES: "WHAT SHOULD I DO BEFORE BUYING A PROPERTY IN PANAMA?",
    line22:
      "Before buying your next house, apartment or land, whether for residence or as a business or investment, first contact us, this is what we will do for you:",
    line23:
      "In order to determine the search area, you must take note of your needs and preferences;",
    line24:
      "If you already know what area you want to live in or have already viewed a property that you think you would like to purchase, please allow us to first investigate the legal status of the property, in order to determine if the property is free of restrictions, encumbrances or irremediable defects. We also take care to assist you in hiring structural inspectors and others who certify the safety and real condition of the property you are about to acquire, or who can simply give you a budget for the investment required in repairs if you decide to buy the property in your country. current state (as is).",
    line25:
      "Every successful transaction begins with a meticulous contract that protects and ensures your interests and contains the will of the parties. Depending on the form of purchase, we will draft for you a Promise of Purchase Contract or a Purchase and Sale Contract.",
    line26:
      "Once the seller has paid his sales taxes, we will assist him in the process of preparing his deeds and the corresponding registration in the Public Registry of Panama.",
    line27:
      "Once your property is registered, we will update the new owner before DGI and ANATI.",
    line28:
      "Having concluded your purchase process, if this is your main home, we offer our services so that your house or apartment is registered with the General Directorate of Income as Tax Family Assets or Main Home, as applicable. We are at your disposal. to clarify your doubts in this regard.",

    TQHVPES: "WHAT SHOULD I DO BEFORE SELLING A PROPERTY IN PANAMA?",
    line29:
      "Before selling your property, whether it is a residence, commercial premises or land, first contact us, this is what we will do for you:",
    line30:
      "We will take a guided tour for you to get to know your property and take photos and videos for advertising.",
    line31:
      "We will verify the legal status of the property in order to ensure that it is ready for sale.",
    line32:
      "If you do not yet have an updated appraisal, we will assist you in scheduling an appraisal by a recognized appraisal house accepted by the country's banks.",
    line33:
      "Having completed the previous steps, we will be able to specify the price for which you wish to sell (high and low sales margins) and we will proceed to advertise your property at our expense throughout the national territory and with our associated broker in the United States.",
    line34:
      "When offers are received for your property, you will be notified immediately so that you can express your acceptance or rejection of it.",
    line35:
      "Once an offer is accepted, we proceed to the preparation of contracts. Depending on whether your property is mortgaged and must be released from it, or whether the buyer will use this figure to acquire the property, we will draft a Contract for you. Promise of Sale or a Purchase and Sale Contract that protects and ensures your interests and contains the Will Agreement of the parties involved in the transaction.",
    line36:
      "The next step is the calculation and payment of the sales interest, namely: The Real Estate Transfer Tax, which consists of 2% of the value that is greater between the sale price and the registered cadastral value; and the Capital Gains Tax that taxes the capital gain from the sale of real estate at 3%.",
    line37:
      "Once the tax returns have been submitted and paid, we proceed to formalize the act at the Notary.",
    line38:
      "Once the deed has been drawn up, and having obtained the Paz y Salvos from DGI, IDAAN and ASEO, we proceed to register it in the Public Registry of Panama. Once the sale is registered, and depending on what was agreed by contract, the balance is paid unpaid amount of the sale price and successfully concludes your transaction. Until the next sale!",

    TICBRES: "THE IMPORTANCE OF A WELL-DRAWN CONTRACT",
    line39:
      "A contract is an agreement between the parties that results in obligations and rights. The preparation and signing of a contract should not be taken lightly. Although it is impossible to contemplate within a contract every possible variable within a situation, the idea is As far as possible, reach a balance by aligning wills on common ground, in search of benefit and protection for the granting parties.",
    line40:
      "Before purchasing a property, consult your attorney to perform due diligence on the property title and ensure that it is free of defects that could hinder the transaction.",
    line41:
      "Then, your lawyer will prepare a Promise of Purchase and Sale Contract that will contemplate the conditions of the purchase, and will guide you through the purchase and sale process.",
    line42:
      "When selling a property, keep in mind that, unless otherwise agreed (established by contract), the seller must declare and pay the Transfer Tax (2%) on the value of the property and the Capital Gains Tax ( 3%) on whichever is greater between the cadastral value and the sale price. These types of matters are established in the contracts. Likewise, the rights and duties of the parties, forms and amounts of payments and compensations, duration, penalties are established. , grounds for nullity or termination and all other details that may mitigate subsequent conflicts of interest or possible non-compliance.",
    line43:
      "Even when these are contracts validly “granted in a foreign country to be fulfilled in Panama”, their effects “shall be governed by Panamanian laws”, as our Civil Code stipulates (Art.6).",
    line44:
      "There are other types of contracts, employment contracts, marriage contracts, rental contracts, intellectual property rights, supply contracts and many more, but in each case, entrust the preparation of your contracts to professionals, so that your will is fulfilled. clearly stated and your interests are well protected. We are distinguished by our transparency in all our dealings!",

    TADDES:
      "MY LAWYER ASKS ME FOR INFORMATION FOR “DUE DILIGENCE, WHAT DOES THIS REFER TO? AND WHY IS IT IMPORTANT?",
    line45:
      'Law 23 of April 27, 2015 defines Due Diligence as the "Set of rules, policies, procedures, processes and procedures that allow reasonable knowledge of the qualitative and quantitative aspects of the client and the final beneficiary, with special attention to the client\'s financial and transactional profile, the origin of their assets and the continuous monitoring of their transactions or operations, when applicable, in accordance with the regulations of this Law, by each supervisory body".',
    line46:
      "Additional emphasis is placed on the fact that “non-financial reporting entities must obtain information and documentation related to the financial and transactional profile of their clients” (Art. 26B) and that they must also “Know the nature of the client's business” (Article 38 These “obligated subjects” must provide said information to the Superintendency if required. Who are the non-financial obligated subjects?",
    line47:
      "Non-financial obligated subjects are those supervised by the Superintendency of non-financial subjects defined in article 40 of Law 124 of 2020, among them are Lawyers when in the exercise of their professional activity they carry out on behalf of a client or for a client any of activities subject to supervision, such as:",
    line48: "Purchase and sale of real estate.",
    line49: "Management of money, securities and other client assets.",
    line50: "Management of bank, savings or securities accounts.",
    line51:
      "Organization of contributions or contributions for the creation, operation or administration of legal entities.",
    line52:
      "Creation, operation or administration of legal persons or legal structures, such as private interest foundations, corporations, trusts and others.",
    line53: "Purchase and sale of legal entities or legal structures.",
    line54:
      "Acting or arranging for a person to act as director or agent of a legal entity or a similar position, in relation to other legal entities.",
    line55:
      "Provide a registered address, commercial address or physical space, postal or administrative address to a legal person or legal structure that is not its property.",
    line56:
      "Act or arrangement for a person to act as a shareholder for a legal entity.",
    line57:
      "Act or arrangement for a person to act as a participant in an express trust or to perform the equivalent function for another form of legal structure.",
    line58:
      "When they provide the services and activities of the resident agent of legal entities constituted or registered in accordance with the laws of the Republic of Panama.",
    line59:
      "It is of utmost importance to understand that before starting the client-attorney relationship, the client must provide us with the following documentation in order to comply with this law:",
    line60: "Identification documents.",
    line61:
      "Information and documentation related to your financial and transactional profile.",
    line62: "Documentation that allows us to know the Nature of your business.",
    line63: "Keep all this information up to date.",
    line64:
      "Sanctions from B/.5,000.00 to B/.5,000,000.00 are established for the obligated subjects for non-compliance with this rule. Also the suspension of the corporate rights of the legal entity related to non-compliance.",
    line65:
      "Sanctions from B/.5,000.00 to B/.100,000.00 are also established for clients who do not comply with delivering all the required documentation and information within the established period.",
    line66:
      "Therefore, having summarized these points, you can be sure that your lawyer requests this information from you in compliance with current legislation on the prevention of money laundering, the financing of terrorism and the financing of the proliferation of weapons of mass destruction, and that It is very important to provide all required documentation and keep it updated in the best compliance with the Law and in order to protect your business and avoid sanctions and suspensions.",

    TPRJPES: "RESIDENCE PERMIT AS A RETIRED AND PENSIONER",
    line67:
      "Retiring in Panama is increasingly convenient. The residence permit as a Retiree and Pensioner grants permanent residence without the need to request an extension and it has attractive benefits such as:",
    line68:
      "Exemption from payment of any deposit, lien or immigration fee in relation to obtaining the Retiree and Pensioner Permit.",
    line69:
      "A one-time tax exemption on the importation of items for household or personal use (up to $10,000).",
    line70:
      "A total tariff exemption every two years when a car is imported for personal or family use.",
    line71:
      "In addition, they are entitled to other benefits for retirees in Panama, learn about some here.",
    line72:
      '“Foreigners who receive retirement or pension from a foreign government, international organization or private company, who enter the national territory to settle there and have sufficient financial means to cover all their subsistence expenses and those of their travel expenses, may request this permit. their dependents in the country. The monthly income or pension cannot be less than one thousand balboas (B/.1,000.00) and must be granted for life."',
    line73:
      "In the event that the applicant has acquired a property on a personal basis in the national territory for a sum greater than one hundred thousand balboas (B/.100,000.00), the pension required may be for a minimum of seven hundred and fifty balboas (B/. 750.00).",
    line74:
      "In the cases of spouses, you may choose to credit the sums of both to meet the minimum established pension, which is one thousand balboas (B/.1,000.00).",
    line75:
      "In the cases of dependent children, their permission will be temporary until they turn twenty-five (25) years old as long as they prove that they complete their studies; however, they will not have the right to stay or to pensioner status. With the exception of those children dependents who suffer from a proven profound disability.",
    line76: "This permit is granted indefinitely.",
    line77: "If you wish to retire in Panama, ",
    line78: "contact us ",
    line79:
      "we can explain the entire process and accompany you at each stage. Welcome to Panama!",
  },
};
