import React from "react";
import { Link } from "react-router-dom";

export default function BackButton({ title, style, url }) {
  return (
    <Link to={url}>
      <div
        style={{
          backgroundColor: "#223d3c",
          color: "#fffffe",
          padding: 10,
          borderRadius: 10,
          float: "right",
          marginBottom: 10,
        }}
      >
        {title}
      </div>
    </Link>
  );
}
