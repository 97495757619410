import React, { useState, useEffect } from "react";
import { lang } from "../Functions/lang";
import { Link } from "react-router-dom";
import { maindata } from "../Data/main";

export default function Realestate(props) {
  const [mainLang, setMainLang] = useState(maindata.language);
  useEffect(() => {
    setMainLang(props.lang);
  }, [props.lang]);

  const writeLines = (item, index) => {
    return <li key={index}>{lang("line" + item, mainLang, 4)}</li>;
  };
  return (
    <div className="top_page">
      <h1>{lang("TSBRES", mainLang, 4)}</h1>
      <ul>{[1, 2, 3, 4, 5].map((item, index) => writeLines(item, index))}</ul>
      <Link to="/apt_house" className="realestate_btn">
        {lang("line6", mainLang, 4)}
      </Link>
    </div>
  );
}
